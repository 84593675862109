import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blog.scss" // add some style if you want!

export default function Blog({ data }: { data: any }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <SEO
        title="Super Productivity – To do list & time tracker for programmers & designers"
        description="Free to do list & time tracker for programmers & designers with build-in Jira & Github Integrations. Download for free or hack it for yourself."
      />

      <div className="container">
        <div className="blog-posts">
          {posts
            .filter((post: any) => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              return (
                <article className="blog-post-preview" key={post.id}>
                  <h1>
                    <Link to={post.frontmatter.path}>
                      {post.frontmatter.title}
                    </Link>
                  </h1>
                  <div className="date">{post.frontmatter.date}</div>
                  <p>{post.excerpt}</p>
                </article>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`
